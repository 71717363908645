<template>
  <div>
    <div>
      <HbPropertySelector
        v-if="level == 'Property'"
        id="facility"
        :items="facilityList"
        item-text="name"
        item-value="id"
        v-validate="'required'"
        v-model="property_id"
        data-vv-name="facility"
        data-vv-as="Property"
        :error="errors.has('facility')"
      />
    </div>
    <div class="mr-6">
      <v-expansion-panels
        v-if="level == 'Corporate' || !disableBtn"
        :key="level"
        elevation="0"
        class="hb-expansion-panel m-0"
        v-model="setupModal"
      >
        <hb-expansion-panel :top-margin="false" key="communication_blackout" :disabled="setExpansionControl()" class="m-0">
          <template v-slot:title> Email/SMS Do Not Disturb </template>
          <template v-if="level == 'Property' && permissionToChange && setupModal == 0" v-slot:actions >
              <hb-switch v-model="header.toggle" right label="Use Corporate Default" @click="saveToggleChange" @click.stop ></hb-switch>
          </template>
          <template v-slot:content>
              <EmailorSMSDND :owner_id="owner_id" :gds_facility_id="gds_facility_id" :level="level" :headerToggle="header.toggle" ref="emailSMSRef" @toggle-status="setToggleValue"></EmailorSMSDND>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel :top-margin="true" key="notes_settings" :disabled="setExpansionControl()">
          <template v-slot:title> Note Categories </template>
          <!-- <template v-if="level == 'Property'" v-slot:actions >
              <hb-switch  v-model="header.toggle" right label="Use Corporate Default">text</hb-switch>
          </template> -->
          <template v-slot:content>
          
            <NoteCategories :level="level" :facilityID.sync="property_id"> </NoteCategories>
          </template>
        </hb-expansion-panel>
      </v-expansion-panels>
      <hb-empty-state
        v-if="level == 'Property' && disableBtn && gds_facility_id"
        message="Please select a Property to continue."
      >
      </hb-empty-state>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "../../../assets/api";
import { notificationMixin } from "../../../mixins/notificationMixin";
import settingsTabMixin from "../../../mixins/settingsTabMixin.js";
import NoteCategories from "./NoteCategories.vue";

import MenuTabs from "../Common/MenuTabs";
import EmailorSMSDND from "./EmailorSMSDND.vue";
import { EventBus } from "../../../EventBus";

export default {
  name: "communications",
  mixins: [notificationMixin, settingsTabMixin],
  data() {
    return {
      menuOptions: [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        },
      ],
      header:{
        toggle:null
      },
      activeTab: "corporate",
      property_id: "",
      gds_facility_id:"",
      owner_id:"",
      disableBtn: true,
      setupModal: [],
      levels:['Corporate','Property'],
      permissionToChange: false
    };
  },

  components: {NoteCategories,EmailorSMSDND},
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      companyData: 'authenticationStore/getCompany',
    //   getAccountingSettings: "accountingStore/getAccountingSettings",
    //   accountingSettings: "accountingStore/accountingSettings",
    }),
    // accountingToggleOn() {
    //   return Object.keys(this.accountingSettings).length &&
    //     this.accountingSettings.toggleAccounting == "1"
    //     ? true
    //     : false;
    // },
  },
  props: {
    level: {
      type: String,
      default: "",
    },
    facilityID: {
      type: String,
      default: "",
    },
    isOPS: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    property_id() {
      if (!this.property_id) {
        this.disableBtn = true;
      } else {
        this.setGdsFacilityId()
        this.disableBtn = false;
      }
    },
    level() {
      if(this.level == 'Corporate'){
      this.setOwnerId()
    }
    },
  },
  mounted() {
    if(this.level == 'Corporate'){
      this.setOwnerId()
    }
    this.setExpansionControl()
  },
  async created() {
    let userData= JSON.parse(localStorage.getItem('hb_user_details'));
    let permission = userData.permissions.findIndex(e => e['label'] === 'manage_email/sms_do_not_disturb_functionality');
    this.permissionToChange = permission != -1? true : false ;
  },
  destroyed() {
  },
  methods: {
    initializeData(){},
    fetchData(){},
    setGdsFacilityId() {
      // set gds facility_id
      this.gds_facility_id = this.facilityList.filter(item => item.id === this.property_id).map(item => item.gds_id)[0]
      this.owner_id = '' // remove owner_id
      if (this.gds_facility_id == '' || !this.gds_facility_id)
        this.showMessageNotification({
          type: "error",
          description: `Unable to find GDS config is missing for selected property`,
        });
    },
    setOwnerId() {
      // set owner id 
      this.owner_id = this.companyData.gds_owner_id
      this.gds_facility_id = '' //reset gds facility id
      if (this.owner_id == '' || !this.owner_id) {
        this.showMessageNotification({
          type: "error",
          description: `Unable to find Owner for selected company`,
        });
      }
    },
    setExpansionControl(){
      if(this.level == this.levels[0]){
        // corporate level 
        if(!this.owner_id) return true
        else return false
      }
      if(this.level == this.levels[1]){
        // property level
        if(!this.gds_facility_id) return true
        else return false
      }
    },
    setToggleValue(toggleFlag){
      this.header.toggle = toggleFlag
    },
    saveToggleChange(){
      this.$refs.emailSMSRef.save('email_and_text', this.header.toggle);
    }
  },
};
</script>

<style scoped></style>
